import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  primaryColor: {
    color: '#616161',
    fontSize: 30,

    '& span': {
      color: '#D6246E',
    },
  },
  logoWrapper: {
    marginTop: theme.spacing(6),
    textAlign: 'center',

    '& img': {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& img': {
        marginBottom: 0,
      },
    },
  },
  stepSubTitle: {
    fontSize: 16,
  },
}));
