import React from 'react';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import { Container, Typography, Box } from '@mui/material';
import { useStyles } from './styles';

const Props = {
  title: PropTypes.node,
  variantTitle: PropTypes.string,
  bgColorProps: PropTypes.string,
};

const DefaultProps = {
  title: (
    <>
      Mind the Graph's trusted by researchers and academics from <br /> 100+ top academic,
      educational and industrial institutions
    </>
  ),
  variantTitle: 'h4',
  bgColorProps: '#FFFFFF',
};

export default function TrustedByResearchers({ variantTitle, title, bgColorProps }) {
  const classes = useStyles();

  return (
    <Box py={5} bgcolor={bgColorProps}>
      <Container>
        <LazyLoad offset={100}>
          <Typography
            className={classes.primaryColor}
            component="h2"
            variant={variantTitle}
            align="center"
          >
            <b>{title}</b>
          </Typography>

          <Box className={classes.logoWrapper}>
            <img
              src="/images/stanford-logo.png"
              height="60"
              width="134"
              loading="lazy"
              alt="Stanford logo"
            />
            <img
              src="/images/ucla-logo.png"
              height="36"
              width="111"
              loading="lazy"
              alt="UCLA logo"
            />
            <img
              src="/images/pearson-logo.png"
              height="87"
              width="122"
              loading="lazy"
              alt="Pearson logo"
            />
            <img
              src="/images/harvard-logo.png"
              height="54"
              width="203"
              loading="lazy"
              alt="Harvard logo"
            />
            <img
              src="/images/abbott-logo.png"
              height="40"
              width="154"
              loading="lazy"
              alt="Abbott logo"
            />
            <img
              src="/images/mcgill-logo.png"
              height="40"
              width="174"
              loading="lazy"
              alt="Mcgill logo"
            />
          </Box>
        </LazyLoad>
      </Container>
    </Box>
  );
}

TrustedByResearchers.propTypes = Props;
TrustedByResearchers.defaultProps = DefaultProps;
